/* ========================================================================
 * Apricot's Utility Module
 * ======================================================================== */

// Same as CBData
const viewports = {
  mobile: {
    prefix: 'xs',
    min: 0,
    max: 767,
  },
  tablet: {
    prefix: 'sm',
    min: 768,
    max: 1023,
  },
  desktop: {
    prefix: 'md',
    min: 1024,
    max: 1247,
  },
  large: {
    prefix: 'lg',
    min: 1248,
    max: 1343,
  },
  xl: {
    prefix: 'xl',
    min: 1344,
    max: 1439,
  },
  xl2: {
    prefix: '3xl',
    min: 1440,
    max: 1727,
  },
  xl3: {
    prefix: '2xl',
    min: 1728,
    max: 99999,
  },
};

// ------------------------------------  KEYBOARD
const KEYS = {
  BACKSPACE: 8,
  TAB: 9,
  ENTER: 13,
  SHIFT: 16,
  CTRL: 17,
  ALT: 18,
  ESC: 27,
  SPACE: 32,
  PAGEUP: 33,
  PAGEDOWN: 34,
  END: 35,
  HOME: 36,
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  PREV: 37,
  NEXT: 39,
  DOWN: 40,
  PLUS: 187,
  PLUSNUMERICKEYPAD: 107,
  MINUS: 189,
  MINUSNUMERICKEYPAD: 109,
  DEL: 46,
  A: 65,
  Z: 90,
  ZERO: 48,
  NINE: 57,
};

// ------------------------------------  FOCUSABLE ELEMENTS
// This list should be used when you only want, elements which can have focus in their current state
const FOCUSABLE_ELEMENTS = [
  'a[href]',
  'area[href]',
  'input:not([disabled]):not([type="hidden"]):not([aria-hidden])',
  'select:not([disabled]):not([aria-hidden])',
  'textarea:not([disabled]):not([aria-hidden])',
  'button:not([disabled]):not([aria-hidden])',
  'iframe',
  'object',
  'embed',
  '[contenteditable]',
  '[tabindex]:not([tabindex^="-"])',
  '[data-cb-focusable]',
];

// This list should be used when we want ALL elements which could get focus regardless of the current state
const FOCUSABLE_ELEMENTS_ALL = [
  'a[href]',
  'area[href]',
  'input',
  'select',
  'textarea',
  'button',
  'iframe',
  'object',
  'embed',
  '[contenteditable]',
  '[tabindex]',
  '[data-cb-focusable]',
];

/**
 * Check is object is empty
 *
 * @export
 * @param {Object} obj
 * @returns {Boolean}
 */
const isEmptyObject = obj => {
  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) return false;
  }

  return true;
};

/**
 * Check if any key in KEYS were clicked
 *
 * @export
 * @param {Object} event
 * @param {String} key
 * @returns {Boolean}
 */

const isKey = (event, key) => {
  return KEYS[key] === event.which ? true : false;
};

/**
 * Get Key name
 *
 * @export
 * @param {Object} event
 * @returns {(String|null)}
 */
const whichKey = event => {
  for (var key in KEYS) {
    if (KEYS[key] === event.which) {
      return key;
    }
  }

  return null;
};

/**
 * Check id element exists
 *
 * @export
 * @param {Element} elem
 * @returns {Boolean}
 */
const elemExists = elem => {
  if (typeof elem !== 'undefined' && elem !== null) {
    return true;
  } else {
    return false;
  }
};

/**
 * Get all elements with the specified class name
 *
 * @export
 * @param {String} className
 * @param {Element} [node=null]
 * @returns {Element[]}
 */
const getByClass = (className, node = null) => {
  node = node || document;

  return node.getElementsByClassName(className);
};

/**
 * Get element whose id property matches the specified string
 *
 * @export
 * @param {String} idName
 * @param {Element} [node=null]
 * @returns {Element}
 */
const getById = (idName, node = null) => {
  node = node || document;

  return node.getElementById(idName);
};

/**
 * Get all elements with the specified tag name
 *
 * @export
 * @param {String} tagName
 * @param {Element} [node=null]
 * @returns {Element[]}
 */
const getByTag = (tagName, node = null) => {
  node = node || document;

  return node.getElementsByTagName(tagName);
};

/**
 * Return the first matching ancestor
 *
 * @export
 * @param {Element} elem
 * @param {String} selector
 * @returns {(String|null)}
 */
const getClosest = (elem, selector) => {
  for (; elem && elem !== document; elem = elem.parentNode) {
    if (selector) {
      if (elem.matches(selector)) return elem;
    } else {
      return elem;
    }
  }

  return null;
};

/**
 * Get elements value
 *
 * @export
 * @param {Element} elem
 * @returns {(String|null)}
 */
const getValue = elem => {
  if (elemExists(elem)) {
    return elem.value;
  } else {
    return null;
  }
};

/**
 * Check if element has a specific class
 *
 * @export
 * @param {Element} elem
 * @param {String} className
 * @returns {Boolean}
 */
const hasClass = (elem, className) => {
  return (' ' + elem.className + ' ').indexOf(' ' + className + ' ') > -1;
};

const checkClassName = (elem, className) => {
  if (elem) {
    if (elem.classList) {
      elem.classList.add(className);
    } else {
      elem.className += ' ' + className;
    }
  }
};

/**
 * Add one or multiple classes to element
 *
 * @export
 * @param {Element} elem
 * @param {String[]|String} className
 */
const addClass = (elem, className) => {
  if (!elemExists(elem)) return;

  if (Array.isArray(className)) {
    className.forEach(function (cName) {
      checkClassName(elem, cName);
    });
  } else {
    checkClassName(elem, className);
  }
};

/**
 * Remove specific class from element
 *
 * @export
 * @param {Element} elem
 * @param {String} className
 */
const removeClass = (elem, className) => {
  if (!elemExists(elem)) return;

  if (elem && elem.classList) {
    if (elem.classList.contains(className)) {
      elem.classList.remove(className);
    }
  }
};

/**
 * Toggle class name
 *
 * @export
 * @param {Element} elem
 * @param {String} className
 * @param {Boolean} state
 */
const toggleClass = (elem, className, state) => {
  if (elem && elem.classList) {
    if (typeof state != 'undefined') {
      if (state) {
        addClass(elem, className);
      } else if (!state) {
        removeClass(elem, className);
      }
    } else {
      elem.classList.toggle(className);
    }
  }
};

/**
 * Toggle between two classes
 *
 * @export
 * @param {Element} elem
 * @param {String} className1
 * @param {String} className2
 */
const toggleBetweenClasses = (elem, className1, className2) => {
  if (elem && elem.classList) {
    if (hasClass(elem, className1)) {
      addClass(elem, className2);

      removeClass(elem, className1);
    } else {
      addClass(elem, className1);

      removeClass(elem, className2);
    }
  }
};

/**
 * Remove element
 *
 * @export
 * @param {Element} elem
 */
const remove = elem => {
  if (typeof elem !== 'undefined' && elem !== null) {
    elem.parentNode && elem.parentNode.removeChild(elem);
  }
};

/**
 * Get first or specific previous sibling
 *
 * @export
 * @param {Element} elem
 * @param {String=} selector
 * @returns {Element}
 */
const getPreviousSibling = (elem, selector) => {
  let sibling = elem.previousElementSibling;

  // If there's no selector, return the first sibling
  if (!selector) return sibling;

  // If the sibling matches our selector, use it
  // If not, jump to the next sibling and continue the loop
  while (sibling) {
    if (sibling.matches(selector)) return sibling;
    sibling = sibling.previousElementSibling;
  }
};

/**
 * Get next or specific next sibling
 *
 * @export
 * @param {Element} elem
 * @param {String=} selector
 * @returns {Element}
 */
const getNextSibling = (elem, selector) => {
  // Get the next sibling element
  let sibling = elem.nextElementSibling;

  // If there's no selector, return the first sibling
  if (!selector) return sibling;

  // If the sibling matches our selector, use it
  // If not, jump to the next sibling and continue the loop
  while (sibling) {
    if (sibling.matches(selector)) return sibling;
    sibling = sibling.nextElementSibling;
  }
};

/**
 * Get parent element
 *
 * @export
 * @param {Element} elem
 * @returns {Element}
 */
const parent = elem => {
  return elem.parentNode;
};

/**
 * Insert element after referenced node
 *
 * @export
 * @param {Element} referenceNode
 * @param {Element} elem
 */
const insertAfter = (referenceNode, elem) => {
  referenceNode.parentNode.insertBefore(elem, referenceNode.nextSibling);
};

/**
 * Insert element before referenced node
 *
 * @export
 * @param {Element} referenceNode
 * @param {Element} elem
 */
const insertBefore = (referenceNode, elem) => {
  referenceNode.parentNode.insertBefore(elem, referenceNode);
};

/**
 * Append element
 *
 * @export
 * @param {Element} elem1
 * @param {Element} elem2
 */
const append = (elem1, elem2) => {
  elem1.appendChild(elem2);
};

/**
 * Detect Browser
 *
 * @export
 * @returns {Object} browser object
 * @returns {String} browser.name
 * @returns {Boolean} browser.type - opera|msie|chrome|firefox|safari
 * @returns {Number} browser.version
 */
const browser = () => {
  var t = true,
    browserObj = {},
    detect;

  detect = function (ua) {
    function getFirstMatch(regex) {
      var match = ua.match(regex);

      return (match && match.length > 1 && match[1]) || '';
    }

    var versionIdentifier = getFirstMatch(/version\/(\d+(\.\d+)?)/i),
      result = {};

    if (/opera|opr/i.test(ua)) {
      result = {
        name: 'Opera',
        opera: t,
        version: versionIdentifier || getFirstMatch(/(?:opera|opr)[\s\/](\d+(\.\d+)?)/i),
      };
    } else if (/msie|trident/i.test(ua)) {
      result = {
        name: 'Internet Explorer',
        msie: t,
        version: getFirstMatch(/(?:msie |rv:)(\d+(\.\d+)?)/i),
      };
    } else if (/chrome|crios|crmo/i.test(ua)) {
      result = {
        name: 'Chrome',
        chrome: t,
        version: getFirstMatch(/(?:chrome|crios|crmo)\/(\d+(\.\d+)?)/i),
      };
    } else if (/firefox|iceweasel/i.test(ua)) {
      result = {
        name: 'Firefox',
        firefox: t,
        version: getFirstMatch(/(?:firefox|iceweaselem)[ \/](\d+(\.\d+)?)/i),
      };
    } else if (/safari/i.test(ua)) {
      result = {
        name: 'Safari',
        safari: t,
        version: versionIdentifier,
      };
    } else {
      result = {};
    }

    return result;
  };

  browserObj = detect(typeof navigator !== 'undefined' ? navigator.userAgent : '');

  return browserObj;
};

/**
 * Add browser + version to element
 *
 * @export
 * @param {Element} elem
 * @param {Boolean} noVersion
 */
const addClassBrowser = (elem, noVersion) => {
  const b = browser();
  if (isEmptyObject(b)) return;

  const className = b.msie ? 'ie' : b.name.toLowerCase();
  const version = !noVersion ? parseInt(b.version, 10) : '';

  if (elemExists(elem)) {
    addClass(elem, className + version);
  }
};

/**
 * Detect OS
 *
 * @export
 * @returns {Object} os object
 * @returns {String} os.name
 */
const OSName = () => {
  var osObj = {},
    detect;

  detect = function (ua) {
    let result = {};

    if (/android/i.test(ua)) {
      result = {
        name: 'Android',
      };
    } else if (/win/i.test(ua)) {
      result = {
        name: 'Windows',
      };
    } else if (/mac/i.test(ua)) {
      result = {
        name: 'MacOS',
      };
    } else if (/linux/i.test(ua)) {
      result = {
        name: 'Linux',
      };
    } else if (/x11/i.test(ua)) {
      result = {
        name: 'UNIX',
      };
    } else {
      result = {
        name: 'Unknown',
      };
    }

    return result;
  };

  osObj = detect(typeof navigator !== 'undefined' ? navigator.userAgent : '');

  return osObj;
};

/**
 * Fix IE(11) Error: Object doesn't support this action
 * - CustomEvent
 * - Object.assign
 * - forEach (on NodeLis)
 * - matches
 *
 * @export
 */
const supportIEObjects = () => {
  const b = browser();
  if (isEmptyObject(b)) return;

  if (b.msie) {
    function CustomEvent(event, params) {
      params = params || {
        bubbles: false,
        cancelable: false,
        detail: undefined,
      };
      var evt = document.createEvent('CustomEvent');
      evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);

      return evt;
    }

    CustomEvent.prototype = window.Event.prototype;

    window.CustomEvent = CustomEvent;

    // missing assign
    if (typeof Object.assign != 'function') {
      Object.assign = function (target) {
        'use strict';
        if (target === null) {
          throw new TypeError('Cannot convert undefined or null to object');
        }

        target = Object(target);
        for (var index = 1; index < arguments.length; index++) {
          var source = arguments[index];
          if (source !== null) {
            for (var key in source) {
              if (Object.prototype.hasOwnProperty.call(source, key)) {
                target[key] = source[key];
              }
            }
          }
        }

        return target;
      };
    }

    // missing forEach on NodeList for IE11
    if (window.NodeList && !NodeList.prototype.forEach) {
      NodeList.prototype.forEach = Array.prototype.forEach;
    }
    // missing match
    if (!Element.prototype.matches) {
      Element.prototype.matches = Element.prototype.msMatchesSelector;
    }
  }
};

/**
 * Add safari + version to element is browser is Safari
 *
 * @export
 * @param {Element} elem
 * @param {Boolean} noVersion
 */
const addClassSafari = (elem, noVersion) => {
  const b = browser();
  if (isEmptyObject(b)) return;

  const className = !noVersion ? 'safari' + parseInt(b.version, 10) : 'safari';

  if (b.safari) {
    if (elemExists(elem)) {
      addClass(elem, className);
    }
  }
};

/**
 * Add Chrome + version to element is browser is chrome
 *
 * @export
 * @param {Element} elem
 * @param {Boolean} noVersion
 */
const addClassChrome = (elem, noVersion) => {
  const b = browser();
  if (isEmptyObject(b)) return;

  const className = !noVersion ? 'chrome' + parseInt(b.version, 10) : 'chrome';

  if (b.chrome) {
    if (elemExists(elem)) {
      addClass(elem, className);
    }
  }
};

/**
 * Add Firefox + version to element is browser is firefox
 *
 * @export
 * @param {Element} elem
 * @param {Boolean} noVersion
 */
const addClassFirefox = (elem, noVersion) => {
  const b = browser();
  if (isEmptyObject(b)) return;

  const className = !noVersion ? 'firefox' + parseInt(b.version, 10) : 'firefox';

  if (b.firefox) {
    if (elemExists(elem)) {
      addClass(elem, className);
    }
  }
};

/**
 * Add ie + version to element is browser is IE
 *
 * @export
 * @param {Element} elem
 * @param {Boolean} noVersion
 */
const addClassIE = (elem, noVersion) => {
  const b = browser();
  if (isEmptyObject(b)) return;

  const className = !noVersion ? 'ie' + parseInt(b.version, 10) : 'ie';

  if (b.msie) {
    if (elemExists(elem)) {
      addClass(elem, className);
    }
  }
};

/**
 * Truncate text
 *
 * @export
 * @param {String} value
 * @param {Number} maxChars
 * @param {String} position
 * @param {String} ellipseText
 * @returns {String}
 */
const textTruncate = (value, maxChars, position, ellipseText) => {
  if (position === 'last') {
    value = value.substring(0, maxChars - ellipseText.length) + ellipseText;
  } else if (position === 'first') {
    value = ellipseText + value.substring(value.length - (maxChars - ellipseText.length));
  } else {
    var middle = Math.floor(maxChars / 2) - ellipseText.length;
    value = value.substring(0, middle) + ellipseText + value.substring(value.length - middle, value.length);
  }

  return value;
};

/**
 * Check if value is blank|undefined|null
 *
 * @export
 * @param {String} value
 * @returns {Boolean}
 */
const isBlank = value => {
  if (!value) {
    value = '';
  }

  return /^\s*$/.test(value);
};

/**
 * Check if value is true
 *
 * @export
 * @param {String} value
 * @returns {Boolean}
 */
const isTrue = value => {
  if (typeof value === 'string') {
    value = value.trim().toLowerCase();
  }
  switch (value) {
    case true:
    case 'true':
    case 1:
    case '1':
    case 'on':
    case 'yes':
      return true;
    default:
      return false;
  }
};

/**
 * Get window dimension
 *
 * @export
 * @returns {Object} dimension object
 * @returns {Number} dimension.width
 * @returns {Number} dimension.height
 */
const windowsDimension = () => {
  const w = window;
  const d = document;
  const e = d.documentElement;
  const g = d.getElementsByTagName('body')[0];
  const x = w.innerWidth || e.clientWidth || g.clientWidth;
  const y = w.innerHeight || e.clientHeight || g.clientHeight;

  return {
    width: x,
    height: y,
  };
};

/**
 * Get elements outer height (height+margin)
 *
 * @export
 * @param {Element} elem
 * @returns {Number}
 */
const outerHeight = elem => {
  let height = elem.offsetHeight;
  const style = getComputedStyle(elem);

  height += parseInt(style.marginTop) + parseInt(style.marginBottom);

  return height;
};

/**
 * Get elements outer width (width+margin)
 *
 * @export
 * @param {Element} elem
 * @returns {Number}
 */
const outerWidth = elem => {
  let width = elem.offsetWidth;
  const style = getComputedStyle(elem);

  width += parseInt(style.marginLeft) + parseInt(style.marginRight);

  return width;
};

/**
 * Get elements height (height)
 *
 * @export
 * @param {Element} elem
 * @param {Boolean} handelHidden
 * @returns {Number}
 */
const height = (elem, handelHidden) => {
  if (handelHidden) {
    return elem.clientHeight || height(elem.parentElement, handelHidden);
  } else {
    return elem.clientHeight;
  }
};

/**
 * Get height of hidden element
 *
 * @export
 * @param {Element} elem
 * @returns {Number}
 */
const getHiddenHeight = elem => {
  if (!elem?.cloneNode) {
    return null;
  }

  const clone = elem.cloneNode(true);

  Object.assign(clone.style, {
    overflow: 'visible',
    height: 'auto',
    maxHeight: 'none',
    minHeight: 'none',
    opacity: '0',
    visibility: 'hidden',
    display: 'block',
    position: 'relative',
  });

  elem.after(clone);
  const hiddenHeight = clone.offsetHeight;

  clone.remove();

  return hiddenHeight;
};

/**
 * Get elements width (width)
 *
 * @export
 * @param {Element} elem
 * @param {Boolean} handelHidden
 * @returns {Number}
 */
const width = (elem, handelHidden) => {
  if (handelHidden) {
    return elem.clientWidth || width(elem.parentElement, handelHidden);
  } else {
    return elem.clientWidth;
  }
};

/**
 * Get elements offset Height (height + border )
 *
 * @export
 * @param {Element} elem
 * @returns {Number}
 */
const offsetHeight = elem => {
  return elem.offsetHeight;
};

/**
 * Get elements offset Width (width + border )
 *
 * @export
 * @param {Element} elem
 * @returns {Number}
 */
const offsetWidth = elem => {
  return elem.offsetWidth;
};

/**
 * Get elements position, relative to the offset parent
 *
 * @export
 * @param {Element} elem
 * @returns {Object}
 */
const position = elem => {
  return {
    top: elem.offsetTop,
    left: elem.offsetLeft,
  };
};

/**
 * Get coordinates of the element, relative to the document.
 *
 * @export
 * @param {Element} elem
 * @returns {Object} object
 * @returns {Number} left
 * @returns {Number} top
 */
const offset = elem => {
  const rect = elem.getBoundingClientRect(),
    scrollLeft = window.scrollX || document.documentElement.scrollLeft,
    scrollTop = window.scrollY || document.documentElement.scrollTop;

  return {
    top: rect.top + scrollTop,
    left: rect.left + scrollLeft,
  };
};

/**
 * Get coordinates of the element, relative to the parent.
 *
 * @export
 * @param {Element} elem
 * @returns {Object} object
 * @returns {Number} top
 * @returns {Number} right
 * @returns {Number} bottom
 * @returns {Number} left
 */
const offsetToParent = elem => {
  const parentElem = parent(elem);

  const rectParent = parentElem.getBoundingClientRect();
  const rect = elem.getBoundingClientRect();

  return {
    top: rect.top - rectParent.top,
    right: rect.right - rectParent.right,
    bottom: rect.bottom - rectParent.bottom,
    left: rect.left - rectParent.left,
  };
};

/**
 * Get browsers viewport
 *
 * @export
 * @returns {Object} viewport
 * @returns {String} viewport.name
 * @returns {String} viewport.prefix
 */
const viewport = () => {
  var viewportType = {},
    getViewPortWidth;

  getViewPortWidth = function (data) {
    var result = {
        name: '',
        prefix: '',
        width: 0,
      },
      body = document.querySelector('body'),
      viewPortWidth = 0;

    body.style.overflow = 'hidden';

    viewPortWidth = windowsDimension().width;

    body.style.overflow = '';

    if (isEmptyObject(data)) {
      return result;
    }

    if (viewPortWidth <= data.mobile.max) {
      result = {
        name: 'mobile',
        prefix: 'xs',
      };
    } else if (viewPortWidth >= data.tablet.min && viewPortWidth <= data.tablet.max) {
      result = {
        name: 'tablet',
        prefix: 'sm',
      };
    } else if (viewPortWidth >= data.desktop.min && viewPortWidth <= data.desktop.max) {
      result = {
        name: 'desktop',
        prefix: 'md',
      };
    } else if (viewPortWidth >= data.large.min && viewPortWidth <= data.large.max) {
      result = {
        name: 'large',
        prefix: 'lg',
      };
    } else if (viewPortWidth >= data.xl.min && viewPortWidth <= data.xl.max) {
      result = {
        name: 'xl',
        prefix: 'xl',
      };
    } else if (viewPortWidth >= data.xl2.min && viewPortWidth <= data.xl2.max) {
      result = {
        name: '2xl',
        prefix: '2xl',
      };
    } else {
      result = {
        name: '3xl',
        prefix: '3xl',
      };
    }
    result.width = viewPortWidth;

    return result;
  };

  viewportType = getViewPortWidth(!isEmptyObject(viewports) ? viewports : {});

  return viewportType;
};

/**
 * Trigger "apricot_breakpointChange" event on document when breakpoint changes
 *
 * @export
 * @param {Boolean} start
 * @returns {Event} event
 * @returns {Object} event.data: viewport object
 */
const breakpoints = start => {
  //Make sure we only declare custom apricot_breakpointChange event once
  if (document.cbBreakpoints) {
    return false;
  }

  const vp = viewport();
  const event = new CustomEvent('apricot_breakpointChange');

  // Default: false
  start = start ? true : false;

  document.cbViewport = vp;

  //If start, trigger event on page load
  if (start) {
    document.addEventListener('DOMContentLoaded', function () {
      // Dispatch the event
      event.data = viewport;

      document.dispatchEvent(event);
    });
  }

  //Check breakpoint status on resize
  window.addEventListener('resize', function () {
    const currentViewport = viewport();
    const oldViewport = document.cbViewport;

    // If viewport has changed trigger event
    if (oldViewport.name !== currentViewport.name) {
      event.data = currentViewport;

      document.dispatchEvent(event);

      document.cbViewport = currentViewport;
    }
  });

  document.cbBreakpoints = true;
};

/**
 * Check if monitor supports  retina display
 *
 * @export
 * @returns {Boolean}
 */
const isRetina = () => {
  return (
    window.devicePixelRatio > 1 ||
    (window.matchMedia &&
      window.matchMedia(
        'only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen  and (min-device-pixel-ratio: 1.3), only screen and (min-resolution: 1.3dppx)',
      ).matches)
  );
};

/**
 * Generate unique ID for HTML element
 *
 * @export
 * @param {Number=} idLength
 * @param {String=} prefix
 * @returns {String}
 */
const uniqueID = (idLength, prefix) => {
  var charArr = '_0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz'.split(''),
    id = '';

  if (!idLength) {
    idLength = Math.floor(Math.random() * charArr.length);
  }

  for (var i = 0; i < idLength; i++) {
    id += charArr[Math.floor(Math.random() * charArr.length)];
  }

  if (prefix) {
    id = prefix + id;
  }

  if (elemExists(document.getElementById(id))) {
    return uniqueID(idLength);
  } else {
    return id;
  }
};

/**
 * Return or set HTML elements attribute
 *
 * @export
 * @param {Element} elem
 * @param {String} attribute
 * @param {String|null}
 */
const attr = (elem, attribute, value = null) => {
  if (!elemExists(elem)) return;

  if (value) {
    elem.setAttribute(attribute, value);
  } else {
    return elem.getAttribute(attribute);
  }
};

/**
 * Remove HTML elements attribute
 *
 * @export
 * @param {Element} elem
 * @param {String} attribute
 */
const removeAttr = (elem, attribute) => {
  if (!elemExists(elem)) return;

  elem.removeAttribute(attribute);
};

/**
 * Detect HTML documents language setting
 *
 * @export
 * @returns {String}
 */
const detectLang = () => {
  var lang = document.documentElement.getAttribute('lang') ? document.documentElement.getAttribute('lang') : 'en';

  if (lang.indexOf('-') >= 0) {
    lang = lang.split('-')[0];
  }

  return lang;
};

/**
 * Convert HTML string to element
 *
 * @param {String} HTML representing any number of sibling elements
 * @return {NodeList}
 */
function htmlToElements(html) {
  var template = document.createElement('template');
  html = html.trim();

  template.innerHTML = html;

  return template.content.childNodes;
}

/**
 * Element contains
 *
 * @export
 * @param {Element} elem
 * @param {Element} child
 */
const contains = (elem, child) => {
  return elem !== child && elem.contains(child);
};

/**
 * Wrap element inside wrapper element
 *
 * @export
 * @param {Element} elem
 * @param {Element} wrapper
 */
const wrap = (elem, wrapper) => {
  elem.parentNode.insertBefore(wrapper, elem);

  wrapper.appendChild(elem);
};

/**
 * Wrap an element around another set of elements
 *
 * @export
 * @param {Element[]} elms
 * @param {Element} wrapper
 */
const wrapAll = (elms, wrapper) => {
  let el = elms.length ? elms[0] : elms;
  let parentElem = el.parentNode;

  wrapper.appendChild(el);

  while (elms.length) {
    wrapper.appendChild(elms[0]);
  }
  if (parentElem) {
    parent.appendChild(wrapper);
  }
};

/**
 * Unwrap element from parent
 *
 * @export
 * @param {Element} elem
 */
const unwrap = elem => {
  let parentElem = elem.parentNode;

  // move all children out of the element
  while (elem.firstChild) {
    parentElem.insertBefore(elem.firstChild, elem);
  }
};

/**
 * Get/Set Element text
 * @export
 * @param {Element} elem
 * @param {String} content
 * @returns {(String|null)}
 */
const elemText = (elem, content = null) => {
  if (content) {
    elem.textContent = content;
  } else {
    return elem.textContent;
  }
};

/**
 * Empty Element
 * @export
 * @param {Element} elem
 */
const empty = elem => {
  elem.innerHTML = '';
};

/**
 * Show element, set display to block
 *
 * @export
 * @param {Element} elem
 */
const show = elem => {
  elem.style.display = 'block';
};

/**
 * Hide element, set display to none
 *
 * @export
 * @param {Element} elem
 */
const hide = elem => {
  elem.style.display = 'none';
};

/**
 * Toggle element visibility
 *
 * @export
 * @param {Element} elem
 * @param {Boolean} state
 */
const toggleDisplay = (elem, state) => {
  if (typeof state !== 'undefined') {
    if (state) {
      show(elem);
    } else if (!state) {
      hide(elem);
    }
  } else {
    // If the element is visible, hide it
    if (window.getComputedStyle(elem).display === 'block') {
      hide(elem);

      return;
    }

    // Otherwise, show it
    show(elem);
  }
};

/**
 * Check if the given variable is a function
 * @export
 * @argument {Any} functionToCheck - variable to check
 * @returns {Boolean} answer to: is a function?
 */
const isFunction = functionToCheck => {
  const getType = {};

  return functionToCheck && getType.toString.call(functionToCheck) === '[object Function]';
};

/**
 * Check if prefers-reduced-motion is set
 * @export
 * @returns {Boolean}
 */
const reduceMotionChanged = () => {
  const query = '(prefers-reduced-motion: reduce)';

  return window.matchMedia(query).matches;
};

/**
 * Check if high contrast is supported
 * @export
 * @param {String} color
 * @returns {Boolean}
 */
const isHighContrast = color => {
  // option to pass default font-color
  if (!color) {
    color = 'rgb(30, 30, 30)';
  }

  if (document.querySelector('body').style.color !== color) {
    return true;
  } else {
    return false;
  }
};

const _message = (mode, message, obj) => {
  switch (mode) {
    case true:
      if (obj) {
        console.error(message, obj);
      } else {
        console.error(message);
      }

      break;
    case false:
      if (obj) {
        console.warn(message, obj);
      } else {
        console.warn(message);
      }

      break;
    default:
      // eslint-disable-next-line no-console
      console.log(message, obj);
  }
};

/**
 * Display error message in terminal
 *
 * @export
 * @param {String} message
 * @param {Object} obj
 */
const error = (message, obj) => {
  _message(true, message, obj);
};

/**
 * Display warning message in terminal
 *
 * @export
 * @param {String} message
 * @param {Object} obj
 */
const warning = (message, obj) => {
  _message(false, message, obj);
};

/**
 * Include a css file, creating new link DOM element
 * @export
 * @argument {String} css_file - variable to check
 * @returns {Boolean} False
 */
const include_css = css_file => {
  const html_doc = document.getElementsByTagName('head')[0];

  const css = document.createElement('link');

  css.setAttribute('rel', 'stylesheet');

  css.setAttribute('type', 'text/css');

  css.setAttribute('href', css_file);

  html_doc.appendChild(css);

  // alert state change

  css.onload = () => {
    const event = new CustomEvent('apricot_cssLoaded');

    html_doc.dispatchEvent(event);
  };

  return false;
};

/**
 * Include a js file, creating new script DOM element
 * @export
 * @argument {String} js_file - variable to check
 * @returns {Boolean} False
 */
const include_js = js_file => {
  const html_doc = document.getElementsByTagName('head')[0];
  const js = document.createElement('script');

  js.setAttribute('type', 'text/javascript');

  js.setAttribute('src', js_file);

  html_doc.appendChild(js);

  js.onload = function () {
    const event = new CustomEvent('apricot_jsLoaded');

    html_doc.dispatchEvent(event);
  };

  return false;
};

/**
 * Disable browser context menu on right click
 *
 * @export
 * @param {Element} elem
 */
const disableRightClick = elem => {
  elem.addEventListener('contextmenu', e => {
    e.preventDefault();

    return false;
  });
};

/**
 * swipe detection
 *
 * @export
 * @param {Element} elem
 * @returns {Event} events swipe_end|swipe_move
 * @returns {Object} event.data: swipe object
 */
const swipe = (elem, removeEvent) => {
  let touchDown = false;
  let originalPosition = null;

  const swipeInfo = e => {
    let x = 0;
    let y = 0;
    let dx, dy;

    if ('undefined' !== typeof e.pageX) {
      x = e.pageX;

      y = e.pageY;
    } else if (e.changedTouches && e.changedTouches[0]) {
      x = e.changedTouches[0].pageX;

      y = e.changedTouches[0].pageY;
    }

    dx = x > originalPosition.x ? 'right' : 'left';

    dy = y > originalPosition.y ? 'down' : 'up';

    return {
      direction: {
        x: dx,
        y: dy,
      },
      offset: {
        x: x - originalPosition.x,
        y: originalPosition.y - y,
      },
    };
  };

  const down = e => {
    touchDown = true;

    if ('undefined' !== typeof e.pageX) {
      originalPosition = {
        x: e.pageX,
        y: e.pageY,
      };
    } else if (e.changedTouches && e.changedTouches[0]) {
      originalPosition = {
        x: e.changedTouches[0].pageX,
        y: e.changedTouches[0].pageY,
      };
    }
  };

  const up = e => {
    const event = new CustomEvent('swipe_end');

    const d = swipeInfo(e);

    event.data = d;

    elem.dispatchEvent(event);

    touchDown = false;

    originalPosition = null;
  };

  const move = e => {
    if (!touchDown) return;

    const event = new CustomEvent('swipe_move');

    event.data = swipeInfo(e);

    elem.dispatchEvent(event);
  };

  if (removeEvent) {
    elem.removeEventListener('touchstart', down, { passive: true });

    elem.removeEventListener('mousedown', down, { passive: true });

    elem.removeEventListener('touchend', up, { passive: true });

    elem.removeEventListener('mouseup', up);

    elem.removeEventListener('touchmove', move, { passive: true });

    elem.removeEventListener('mousemove', move);
  } else {
    elem.addEventListener('touchstart', down, { passive: true });

    elem.addEventListener('mousedown', down);

    elem.addEventListener('touchend', up, { passive: true });

    elem.addEventListener('mouseup', up);

    elem.addEventListener('touchmove', move, { passive: true });

    elem.addEventListener('mousemove', move);
  }

  return true;
};

/**
 * copy to clipboard
 *
 * @export
 * @param {Element} elem
 * @param {String} text
 */

const copyToClipboard = text => {
  if (window.clipboardData && window.clipboardData.setData) {
    window.clipboardData.clearData();

    return window.clipboardData.setData('Text', text);
  } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
    var textarea = document.createElement('textarea');
    textarea.textContent = text;

    textarea.style.position = 'fixed';

    document.body.appendChild(textarea);

    textarea.select();
    try {
      return document.execCommand('copy');
    } catch (ex) {
      console.warn('Failed to copy.', ex);

      return false;
    } finally {
      document.body.removeChild(textarea);
    }
  }
};

/**
 * Block ESC key for Apricot components
 *
 */
const blockEsc = () => {
  attr(document.getElementsByTagName('body')[0], 'data-cb-esc', 'true');
};

/**
 * UN-Block ESC key for Apricot components
 *
 * @export
 * @param {Number} ms
 */
const unBlockEsc = (ms = 150) => {
  setTimeout(() => {
    removeAttr(document.getElementsByTagName('body')[0], 'data-cb-esc');
  }, ms);
};

/**
 * Finding the active element in a shadow root
 *
 * @export
 * @param {Node} root
 */
const getActiveElementShadowRoot = (root = document) => {
  const activeEl = root.activeElement;

  if (!activeEl) {
    return null;
  }

  if (activeEl.shadowRoot) {
    return getActiveElementShadowRoot(activeEl.shadowRoot);
  } else {
    return activeEl;
  }
};

/**
 * Get Shadow root of an element
 *
 * @export
 * @param {Node} element
 */
const getShadowRoot = element => {
  while (element && element.parentNode && (element = element.parentNode)) {
    if (element instanceof ShadowRoot) {
      return element;
    }
  }

  return null;
};

/**
 * Get browsers zoom Ration
 *
 * @export
 * @param {Boolean} exact
 * @returns {number} zoomRatio
 */
const zoomRatio = exact => {
  // make sure that it's running on the client side
  if (typeof window === 'undefined') {
    return null;
  }

  // return Math.round((window.outerWidth / window.innerWidth) * 100) / 100;
  const zoomLevel = Math.round((window.outerWidth / window.innerWidth) * 100) / 100;

  if (exact) {
    return zoomLevel;
  } else {
    // Define common zoom factors
    const commonZoomFactors = [0.25, 0.33, 0.5, 0.67, 0.75, 0.8, 0.9, 1, 1.1, 1.25, 1.5, 1.75, 2, 2.5, 3, 4, 5];

    // Find the closest common zoom factor
    const closestZoomFactor = commonZoomFactors.reduce((prev, curr) => {
      return Math.abs(curr - zoomLevel) < Math.abs(prev - zoomLevel) ? curr : prev;
    });

    return closestZoomFactor;
  }
};

/**
 * Trigger "apricot_browserRatioChange" event on document when the browsers zoom ratio changes
 *
 * @export
 * @param {Boolean} start
 * @param {Boolean} exact
 * @returns {Event} event
 * @returns {Object} event.data: ratio object
 */

const browserRatio = (start = false, exact = false) => {
  //Make sure we only declare custom cbBrowserRatio event once
  if (document.cbBrowserRatio) {
    return false;
  }

  const zr = zoomRatio(exact);
  document.cbZoomRatio = zr;

  // Default: false
  start = start ? true : false;

  //If start, trigger event on page load
  if (start) {
    document.addEventListener('DOMContentLoaded', () => {
      const loadRatio = zoomRatio(exact);
      const eventOnLoad = new CustomEvent('apricot_breakpointChange');

      // Dispatch the event
      eventOnLoad.data = {
        ratio: loadRatio,
      };

      document.cbZoomRatio = loadRatio;

      document.dispatchEvent(eventOnLoad);
    });
  }

  //Check ratio status on resize
  window.addEventListener('resize', () => {
    const currentRatio = zoomRatio(exact);
    const oldRatio = document.cbZoomRatio;

    // If viewport has changed trigger event
    if (currentRatio != oldRatio) {
      const event = new CustomEvent('apricot_browserRatioChange');

      event.data = {
        ratio: currentRatio,
      };

      document.dispatchEvent(event);

      document.cbZoomRatio = currentRatio;
    }
  });

  document.cbBrowserRatio = true;
};

/**
 * Recursively searches for a key within a nested object and returns its value if found.
 * Uses an iterative approach with a stack to avoid deep recursion and a Set to handle circular references.
 *
 * @param {Object} obj - The object to search within.
 * @param {String} key - The key to search for.
 * @returns {Any} - The value associated with the key if found, otherwise undefined.
 */
const deepSearch = (obj, key) => {
  if (obj === null || typeof obj !== 'object') {
    return undefined;
  }

  const stack = [obj];
  const visited = new Set();

  while (stack.length > 0) {
    const current = stack.pop();

    if (visited.has(current)) {
      continue;
    }
    visited.add(current);

    if (Object.prototype.hasOwnProperty.call(current, key)) {
      return current[key];
    }

    for (const k in current) {
      if (Object.prototype.hasOwnProperty.call(current, k) && typeof current[k] === 'object' && current[k] !== null) {
        stack.push(current[k]);
      }
    }
  }

  return undefined;
};

export default {
  KEYS,
  FOCUSABLE_ELEMENTS,
  FOCUSABLE_ELEMENTS_ALL,
  addClass,
  addClassBrowser,
  addClassChrome,
  addClassFirefox,
  addClassIE,
  addClassSafari,
  append,
  attr,
  blockEsc,
  breakpoints,
  browser,
  browserRatio,
  contains,
  copyToClipboard,
  detectLang,
  deepSearch,
  disableRightClick,
  elemExists,
  elemText,
  empty,
  error,
  getActiveElementShadowRoot,
  getByClass,
  getById,
  getByTag,
  getClosest,
  getHiddenHeight,
  getNextSibling,
  getShadowRoot,
  getPreviousSibling,
  getValue,
  hasClass,
  height,
  hide,
  htmlToElements,
  include_css,
  include_js,
  insertAfter,
  insertBefore,
  isBlank,
  isEmptyObject,
  isFunction,
  isHighContrast,
  isKey,
  isRetina,
  isTrue,
  offset,
  offsetHeight,
  offsetToParent,
  offsetWidth,
  OSName,
  outerHeight,
  outerWidth,
  parent,
  position,
  reduceMotionChanged,
  remove,
  removeAttr,
  removeClass,
  show,
  supportIEObjects,
  swipe,
  textTruncate,
  toggleClass,
  toggleBetweenClasses,
  toggleDisplay,
  unBlockEsc,
  uniqueID,
  unwrap,
  viewport,
  warning,
  whichKey,
  width,
  windowsDimension,
  wrap,
  wrapAll,
  zoomRatio,
};
